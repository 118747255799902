<template>
  <div class="my-4">
    <div class="font-bold text-md">{{ title }}</div>
    <div class="py-2 flex">
      <!-- SHOW LOGO -->
      <div class="w-full lg:w-3/12">
        <label class="cursor-pointer" :for="`show-logo-${type}`">
          <i class="bx bx-info-circle" title="Se mostrara el LOGO de la empresa"></i>
          Mostrar LOGO
        </label>
      </div>
      <div class="w-full lg:w-1/12">
        <el-switch :id="`show-logo-${type}`" v-model="showLogo" :disabled="disabled.logo" />
      </div>

      <!-- SHOW NAME-->
      <div class="w-full lg:w-3/12">
        <label class="cursor-pointer" :for="`show-business-name-${type}`">
          <i class="bx bx-info-circle" title="Se mostrara el nombre de la empresa"></i>
          Mostrar Nombre de Empresa
        </label>
      </div>
      <div class="w-full lg:w-1/12">
        <el-switch :id="`show-business-name-${type}`" v-model="showName" :disabled="disabled.businessName" />
      </div>

      <!-- SHOW PASSCODE -->
      <div class="w-full lg:w-3/12">
        <label class="cursor-pointer" :for="`show-passcode-${type}`">
          <i class="bx bx-info-circle" title="Se mostrara el passcode del dispositivo"></i>
          Mostrar Passcode
        </label>
      </div>
      <div class="w-full lg:w-1/12">
        <el-switch :id="`show-passcode-${type}`" v-model="showPasscode" :disabled="disabled.passcode" />
      </div>

    </div>
    <div v-if="showLogo" class="w-full lg:w-4/12 flex">
      <div class="w-full mr-2">
        <strong>Ancho (px)</strong>
        <el-input type="number" v-model="logoX" />
      </div>
      <div class="w-full ml-2">
        <strong>Alto (px)</strong>
        <el-input type="number" v-model="logoY" />
      </div>
    </div>
  </div>
</template>
<script>
import { DefaultPrintConfiguration } from '@/constants';

export default {
  name: 'PrintConfigForm',
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    config: {
      type: Object,
      default: () => DefaultPrintConfiguration,
    },
    disabled: {
      type: Object,
      default: () => ({
        logo: false,
        businessName: false,
        passcode: false,
      }),
    }
  },
  computed: {
    showLogo: {
      get() {
        return this.config.showLogo;
      },
      set(value) {
        this.onChange('showLogo', value);
      },
    },
    logoX: {
      get() {
        return this.config.logoX;
      },
      set(value) {
        this.onChange('logoX', value);
      },
    },
    logoY: {
      get() {
        return this.config.logoY;
      },
      set(value) {
        this.onChange('logoY', value);
      }
    },
    showName: {
      get() {
        return this.config.showBusinessName;
      },
      set(value) {
        this.onChange('showBusinessName', value);
      }
    },
    showPasscode: {
      get() {
        return this.config.showPasscode;
      },
      set(value) {
        this.onChange('showPasscode', value);
      }
    },
  },
  methods: {
    onChange(key, value) {
      this.$emit('config:update', { key: this.type, value: { ...this.config, [key]: value } });
    },
  },
}
</script>